<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent class="py-8" title="Zero Gap Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Zero Gap Test Description"
              size="medium"
            />
            <a
              target="_blank"
              :href="require('../../assets/IPSeries/UNGapDetails-min.png')"
            >
              <img src="@/assets/IPSeries/UNGapDetails-min.png" />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The zero-gap test determines whether the material can propagate a
              detonation from high confinement and defined shock (~200 kbar). If
              the material can propagate a detonation, it should be classified
              as an explosive (Class 1.1 if also easily initiated) in a
              manufacturing operation.
            </p>
            <TitleComponent class="pt-8" title="Equivalence" size="medium" />
            <p class="p-light">
              This test is equivalent to
              <router-link class="link" to="/un-gap-test"
                >UN Test 1 (a)</router-link
              >
              in the UN Manual of Tests and Criteria.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th class="px-4 py-2">Key Parameter</th>
                    <th class="px-4 py-2">Objectives</th>
                    <th class="px-4 py-2">Origin</th>
                    <th class="px-4 py-2">Specs</th>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Number of trials
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Sufficient to ensure a repeatable result
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">2 trials</td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">Booster</td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a strong, repeatable, stable shock front to the
                      top of the sample
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      160 grams of RDX/wax (95/5) or PETN/TNT (50/50), 50 mm
                      diameter, ~50mm length
                    </td>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Confining medium (steel tube)
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide confinement, increasing the susceptibility of the
                      substance to detonation; evidence of reaction type
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      <u>UN Gap (new)</u>: Cold-drawn, seamless, carbon steel
                      tube 48 ± 2 mm (1.875-in) OD, 4.0 ± 0.1 mm wall (40 ± 2.2
                      mm (1.5-in) ID), 400 ± 5 mm (16-in) long.<br />
                      <u>UN Gap (legacy)</u>: Cold-drawn, seamless, carbon steel
                      tube 47.6mm (1.875-in) OD, 5.6mm wall (36.5mm (1.44-in)
                      ID), 406mm (16-in) long.<br />
                      <u>NOTE:</u> MIL-STD-1751A Method 1041 specifies mild
                      steel (SAE 1015 – 1040); TB 700-2 specifies ASTM 1026 or
                      Drawn Over Mandrel (DOM)
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">Spacer</td>
                    <td class="border px-4 py-2 font-medium">
                      Separate the steel tube from the witness plate
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      MIL-STD-1751A Method 1041: The small stand-off (0.063-inch
                      gap) between the acceptor and the witness plate was
                      introduced<br />
                      to prevent the witness plate from shattering and thereby
                      facilitate interpretation of test results.
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      1.6 ± 0.2mm (1/16-in) thick
                    </td>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Witness plate specifications
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Evidence of reaction type
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      MIL-STD-1751A Method 1041: Some laboratories prefer to use
                      witness plates with larger length and width, e.g., 9-in
                      square, to<br />
                      reduce the likelihood of shattering the witness plate (an
                      acceptable variation)
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      150 ± 10mm (6-in) square and 3.2 ± 0.2mm (1/8-in) thick<br />
                      <u>NOTE:</u> MIL-STD-1751A Method 1041 specifies mild
                      steel (SAE 1015 – 1026); TB 700-2 (2012) specifies ASTM
                      1020 – 1025
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">Offset</td>
                    <td class="border px-4 py-2 font-medium">
                      Offset the witness plate from the ground
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      40 x 90 mm (2 x 4 in) wood blocks at the edge of the
                      witness plate
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Damage to the witness plate
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Visual post-test inspection
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Hole punched through the witness plate: Class 1
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Damage to the steel tube
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Visual post-test inspection
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Complete fragmentation of the tube: Class 1
                    </td>
                  </tr>
                </tbody>
              </table>
              <span class="p-light">*OR relationship</span>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="Example Photo of Witness Plate Not Holed, Pipe Not Completely Fragmented (-)"
              size="medium"
            />
            <a
              target="_blank"
              :href="require('../../assets/IPSeries/no-go-min.jpg')"
            >
              <img
                class="m-auto"
                style="width:80%;"
                src="@/assets/IPSeries/no-go-min.jpg"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 md:pl-2">
            <TitleComponent
              class="pt-8 mb-10"
              title="Example Video of Witness Plate Not Holed (-)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166130251?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166130251"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="Example Photo of Holed Witness Plate (+)"
              size="medium"
            />
            <a
              target="_blank"
              :href="require('../../assets/IPSeries/go-min.jpg')"
            >
              <img
                class="m-auto"
                style="width:80%;"
                src="@/assets/IPSeries/go-min.jpg"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 md:pl-2">
            <TitleComponent
              class="pt-8 mb-10"
              title="Example Video of Holed Witness Plate (+)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166130250?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166130250"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
